@font-face {
font-family: '__Ubuntu_737716';
src: url(/_next/static/media/7f7f7f543df27dc4-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__Ubuntu_Fallback_737716';src: local("Arial");ascent-override: 91.04%;descent-override: 18.46%;line-gap-override: 2.74%;size-adjust: 102.37%
}.__className_737716 {font-family: '__Ubuntu_737716', '__Ubuntu_Fallback_737716'
}

.Tree_app__OXB1g {
  height: 100%;
}

.Tree_treeRoot__CsNeI {
  height: 100%;
}

.Tree_draggingSource__KF8Jf {
  opacity: 0.3;
}

.Tree_dropTarget__m8d6y {
  background-color: #e8f0fe;
}
.Tree_listStyle__NT4i_ {
  list-style-type: none;
}

.Tree_container__x1i21 {
  padding-left: 15px;
}

.Tree_app__tljbO {
  height: 100%;
}

.Tree_treeRoot__xZIND {
  height: 100%;
}

.Tree_draggingSource__kPRU1 {
  opacity: 0.3;
}

.Tree_dropTarget__TeF1a {
  background-color: #e8f0fe;
}

.Tree_listStyle__pRR0h {
  list-style-type: none;
  align-items: center;
  padding-top: 4px;
}

.Tree_container__t05IO {
  padding-left: 0px;
}

.Tree_placeholderContainer__AFMD2 {
  position: relative;
}

